import Vue from 'vue'
import {$} from '@/facade'
import {Organization} from '@/model/resource/Organization'
import {Repository} from '@/model/resource/Repository'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'
import {RepositoryCollection} from '@/model/collection/RepositoryCollection'
import {CommitCollection} from '@/model/collection/CommitCollection'
import {GithubActivityStats} from '@/model/stats/GithubActivityStats'
import {Dictionary} from '@/enums/Dictionary'
import {IResource} from '@simpli/resource-collection'
import {debounce} from 'lodash'
import {StackedChartStats} from '@/model/stats/StackedChartStats'

export enum CAType {
  REPOSITORY,
  ORGANIZATION,
}

export enum StatsTypeEnum {
  COMMIT = 1,
  WEEKLY = 2,
}

export enum PeriodDaysEnum {
  SEVEN = 7,
  THIRTY = 30,
}

export interface CAIterable extends IResource {
  caType: CAType
  activitiesCount: number
  logoUrl: string | null
  lastUpdateAt: string | null
  title: string | null
  colorHex: string | null
}

export class CAController {
  // Section 1
  readonly organizationActivitiesCollection = new OrganizationCollection()
  readonly githubActivityStats = new GithubActivityStats()

  //Section 2
  readonly stackedChartStats = new StackedChartStats()

  _activityVolumeDictionary: Dictionary<boolean> = {}

  statsType: IResource = this.commitResource
  statsPeriod: IResource = this.weeklyPeriodResource

  readonly statsTypeOptions: IResource[] = [
    this.commitResource,
    this.weeklyContributionResource,
  ]

  readonly statsPeriodOptions: IResource[] = [
    this.monthlyPeriodResource,
    this.weeklyPeriodResource,
  ]

  // Section 3
  lastActiveProjectsCollection = new RepositoryCollection()

  // Section 4
  lastCommitsCollection = new CommitCollection()

  // private selectors

  // Section 1
  /**
   * Used to manage the tree selector (arrows)
   */
  private _selectedIteratorIndex = 0

  /**
   * Used to manage the organization selector separately
   */
  private _selectedOrganizationIndex: number | null = null // null = no selected

  /**
   * Used to manage the repository selector separately
   */
  private _selectedRepositoryIndex: number | null = null // null = no selected

  // Section 2

  /**
   * Used to manage the checkbox selectors in the second section
   */
  private _selectedActivityVolumeIndex: number[] | null = [] // null = no selected

  /**
   * Filter period for each step
   */
  private _periodInHours: number | null = null // null = no filter

  /**
   * Filter number of steps
   */
  private _range: number | null = null // null = no filter

  private _debounceSelectActivityVolume = debounce(
    () => this.selectActivityVolume(),
    1000
  )

  constructor() {
    // collections
    this.lastActiveProjectsCollection.idOrganizationFk = this.computedIdOrganizationFk
    this.lastCommitsCollection.idRepositoryFk = this.computedIdRepositoryFk

    // stats
    this.githubActivityStats.idOrganizationFk = this.computedIdOrganizationFk
    this.githubActivityStats.idRepositoryFk = this.computedIdRepositoryFk
  }

  get activityVolumeDictionary() {
    return this._activityVolumeDictionary
  }

  set activityVolumeDictionary(val: Dictionary<boolean>) {
    this._activityVolumeDictionary = val
    this._debounceSelectActivityVolume()
  }

  setActivityVolumeOfKey(key: string, val: boolean) {
    Vue.set(this._activityVolumeDictionary, key, val)
    this._debounceSelectActivityVolume()
  }

  private get computedIdOrganizationFk() {
    return this.selectedOrganization?.$id ? [this.selectedOrganization.$id] : []
  }

  private get computedIdRepositoryFk() {
    return this.selectedActivityVolume
      .filter(it => it.caType === CAType.REPOSITORY)
      .map(it => it.$id)
  }

  private get volumeActivityFilter() {
    let idRepositoryFk: number[]
    let idOrganizationFk: number[]

    if (this.selectedActivityVolume.length === 0 && this.selectedOrganization) {
      idRepositoryFk = []
      idOrganizationFk = [this.selectedOrganization.$id]
    } else if (this._selectedRepositoryIndex !== null) {
      idRepositoryFk = this.selectedActivityVolume.map(it => it.$id)
      idOrganizationFk = []
    } else {
      idRepositoryFk = []
      idOrganizationFk = this.selectedActivityVolume.map(it => it.$id)
    }

    return {
      idRepositoryFk,
      idOrganizationFk,
    }
  }

  private get commitResource(): IResource {
    return {
      $id: StatsTypeEnum.COMMIT,
      $tag: $.t('components.caController.commit') as string,
    }
  }

  private get weeklyContributionResource(): IResource {
    return {
      $id: StatsTypeEnum.WEEKLY,
      $tag: $.t('components.caController.linesOfCode') as string,
    }
  }

  private get weeklyPeriodResource(): IResource {
    return {
      $id: 1,
      $tag: $.t('components.caController.7days') as string,
    }
  }

  private get monthlyPeriodResource(): IResource {
    return {
      $id: 2,
      $tag: $.t('components.caController.30days') as string,
    }
  }

  async init() {
    await this.populate()
    this.selectAllActivityVolume()
  }

  /**
   * Populate all content that needs
   */
  async populate() {
    if (this.selectedOrganization && !this.selectedRepository) {
      this.selectAllActivityVolume()
    }

    const promises: Promise<unknown>[] = [
      this.populateOrganizationActivities(),
      this.populateGithubActivityStats(),
      this.reloadLastCommits(),
      this.reloadLastActiveProjects(),
    ]

    await $.await.run('softQuery', async () => await Promise.all(promises))

    await this.populateCharts()
  }

  selectAllActivityVolume() {
    this._selectedActivityVolumeIndex = this.allActivityVolume.map((it, i) => i)
    this._activityVolumeDictionary = {}
    this.allActivityVolume.forEach((it, i) => {
      Vue.set(this._activityVolumeDictionary, i, true)
    })
  }

  /**
   * Populate content for Charts
   */
  async populateCharts(range?: number) {
    this.stackedChartStats.organization = this.selectedOrganization
      ? [this.selectedOrganization]
      : null
    this.stackedChartStats.range =
      (range || this._range) ?? PeriodDaysEnum.SEVEN

    await this.stackedChartStats.getStats()
  }

  /**
   * Changes both organization index and repository index
   * Note: dispatchers selectors affect all indexes directly or indirectly
   */
  async dispatchPairSelection(
    repositoryIndex?: number | null,
    organizationIndex?: number | null
  ) {
    if (repositoryIndex !== undefined) {
      this.selectedRepositoryIndex = repositoryIndex
    }

    if (organizationIndex !== undefined) {
      this.selectedOrganizationIndex = organizationIndex
    }

    const organization = this.selectedOrganization

    if (organization) {
      const repository = this.selectedRepository

      let index
      if (repository) {
        index = this.allIterators.findIndex(
          it => it?.caType === CAType.REPOSITORY && it?.$id === repository.$id
        )
      } else {
        index = this.allIterators.findIndex(
          it =>
            it?.caType === CAType.ORGANIZATION && it?.$id === organization.$id
        )
      }

      if (index >= 0) this._selectedIteratorIndex = index
    } else {
      this._selectedIteratorIndex = 0
      await this.populateCharts()
    }
    await this.populate()

    if (organizationIndex !== undefined) {
      this.selectAllActivityVolume()
    }
  }

  /**
   * Changes iterator index
   * Note: dispatchers selectors affect all indexes directly or indirectly
   */
  async dispatchIteratorSelection(index: number) {
    this.selectedIteratorIndex = index
    await this.populate()

    if (this.selectedIterator?.caType === CAType.ORGANIZATION) {
      this.selectAllActivityVolume()
    }
  }

  /**
   * Expands the session 'Last Commits'
   */
  async expandLastCommits() {
    this.lastCommitsCollection.idRepositoryFk = this.computedIdRepositoryFk
    await this.lastCommitsCollection.expand()
  }

  /**
   * Expands the session 'Last Active Projects'
   */
  async expandLastActiveProjects() {
    this.lastActiveProjectsCollection.idOrganizationFk = this.computedIdOrganizationFk
    await this.lastActiveProjectsCollection.expand()
  }

  /**
   * Populates the session 'Organization Activities'
   */
  private async populateOrganizationActivities() {
    this.organizationActivitiesCollection.orderBy = 'linesOfCode'
    this.organizationActivitiesCollection.asc = false
    await this.organizationActivitiesCollection.listOrganization()
  }

  /**
   * Reloads the session 'Last Active Projects'
   */
  private async reloadLastActiveProjects() {
    this.lastActiveProjectsCollection = new RepositoryCollection()
    this.lastActiveProjectsCollection.orderBy = 'lastCommitAt'
    this.lastActiveProjectsCollection.perPage = 6
    this.lastActiveProjectsCollection.asc = false
    this.lastActiveProjectsCollection.idOrganizationFk = this.computedIdOrganizationFk
    await this.lastActiveProjectsCollection.queryToFirstPage()
  }

  /**
   * Reloads the session 'Last Commits'
   */
  private async reloadLastCommits() {
    this.lastCommitsCollection = new CommitCollection()
    this.lastCommitsCollection.orderBy = 'datetime'
    this.lastCommitsCollection.perPage = 6
    this.lastCommitsCollection.asc = false
    this.lastCommitsCollection.idOrganizationFk = this.computedIdOrganizationFk
    this.lastCommitsCollection.idRepositoryFk = this.computedIdRepositoryFk
    await this.lastCommitsCollection.queryToFirstPage()
  }

  /**
   * Load github activity stats for session 'Organization Activities'
   */
  private async populateGithubActivityStats() {
    this.githubActivityStats.idOrganizationFk = this.computedIdOrganizationFk
    this.githubActivityStats.idRepositoryFk = this.computedIdRepositoryFk

    await this.githubActivityStats.getStats()
  }

  /**
   * Returns true if session 'Last Active Projects' should be hidden
   */
  get isLastActiveProjectsHidden() {
    return !this._selectedRepositoryIndex
  }

  /**
   * Lists all available organizations
   */
  get allOrganizations() {
    return this.organizationActivitiesCollection.items
  }

  /**
   * Lists all repositories from selected organization
   */
  get allRepositoryFromSelectedOrganization() {
    return this.selectedOrganization?.repositories ?? []
  }

  /**
   * Lists all activity volume (repository or organization)
   */
  get allActivityVolume(): CAIterable[] {
    if (this.selectedActivityVolumeIndexType === CAType.REPOSITORY) {
      return this.allRepositoryFromSelectedOrganization
    }

    return this.allOrganizations
  }

  /**
   * Gets the sequence list of tree of organization and repository
   */
  get allIterators() {
    const iterators: (CAIterable | null)[] = [null] // the first one means all organization

    for (const organization of this.allOrganizations) {
      iterators.push(organization)

      for (const repository of organization.repositories) {
        iterators.push(repository)
      }
    }

    return iterators
  }

  /**
   * Gets the selected organization
   * Returns null if it is not selected
   */
  get selectedOrganization(): Organization | null {
    if (this._selectedOrganizationIndex !== null) {
      return this.allOrganizations[this._selectedOrganizationIndex] ?? null
    }

    return null
  }

  /**
   * Gets the selected repository
   * Returns null if it is not selected
   */
  get selectedRepository(): Repository | null {
    if (this._selectedRepositoryIndex !== null) {
      return (
        this.allRepositoryFromSelectedOrganization[
          this._selectedRepositoryIndex
        ] ?? null
      )
    }

    return null
  }

  /**
   * Gets the selected iterator - either repository or organization
   * Returns null if it is not selected
   */
  get selectedIterator() {
    return this._selectedIteratorIndex
      ? this.allIterators[this._selectedIteratorIndex]
      : null
  }

  /**
   * Gets the selected activity volume
   * Returns null if it is not selected
   */
  get selectedActivityVolume() {
    return this.allActivityVolume.filter((item, i) =>
      this._selectedActivityVolumeIndex?.includes(i)
    )
  }

  /**
   * Deselect all selected activity volumes
   */
  deselectedActivityVolume() {
    this._selectedActivityVolumeIndex = []
  }

  /**
   * Gets selected iterator index
   */
  get selectedIteratorIndex() {
    return this._selectedIteratorIndex
  }

  /**
   * Sets selected iterator index
   */
  set selectedIteratorIndex(val) {
    this._selectedIteratorIndex = val

    const iterator = this.allIterators[val]

    if (iterator?.caType === CAType.REPOSITORY) {
      const repository = iterator as Repository
      const organizationId = repository.organization?.idOrganizationPk

      if (this.selectedOrganization?.idOrganizationPk !== organizationId) {
        this.selectedOrganizationIndex = this.allOrganizations.findIndex(
          it => it.idOrganizationPk === organizationId
        )
      }

      const i1 = this.allRepositoryFromSelectedOrganization.findIndex(
        it => it.$id === repository.$id
      )

      if (i1 >= 0) {
        this.selectedRepositoryIndex = i1
        this.activityVolumeDictionary = {
          [i1]: true,
        }
      }

      const i2 = this.allOrganizations.findIndex(
        it => it.$id === repository.idOrganizationFk
      )

      if (i2 >= 0) this.selectedOrganizationIndex = i2
    } else if (iterator?.caType === CAType.ORGANIZATION) {
      const organization = iterator as Organization

      const i = this.allOrganizations.findIndex(
        it => it.$id === organization.$id
      )

      this.selectedRepositoryIndex = null

      if (i >= 0) {
        this.selectedOrganizationIndex = i
        this.activityVolumeDictionary = {}
      }
    } else {
      this.selectedRepositoryIndex = null
      this.selectedOrganizationIndex = null
    }
  }

  /**
   * Gets selected organization index
   */
  get selectedOrganizationIndex() {
    return this._selectedOrganizationIndex
  }

  /**
   * Sets selected organization index
   */
  set selectedOrganizationIndex(index) {
    this._selectedOrganizationIndex = index

    if (index !== null) {
      this._selectedActivityVolumeIndex = [index]
    }
  }

  /**
   * Gets selected repository index
   */
  get selectedRepositoryIndex() {
    return this._selectedRepositoryIndex
  }

  /**
   * Sets selected repository index
   */
  set selectedRepositoryIndex(index) {
    this._selectedRepositoryIndex = index

    if (index !== null) {
      this._selectedActivityVolumeIndex = [index]
      this._activityVolumeDictionary = {[index]: true}
    } else if (this._selectedOrganizationIndex !== null) {
      this._selectedActivityVolumeIndex = [this._selectedOrganizationIndex]
    }
  }

  get isRepositorySelection() {
    return this.selectedActivityVolumeIndexType === CAType.REPOSITORY
  }

  /**
   * Gets the type of activity volume index
   */
  get selectedActivityVolumeIndexType(): CAType {
    if (
      this._selectedOrganizationIndex !== null ||
      this._selectedRepositoryIndex !== null
    ) {
      return CAType.REPOSITORY
    }

    return CAType.ORGANIZATION
  }

  /**
   * Gets the type of activity volume index
   */
  get periodInHours() {
    return this._periodInHours
  }

  /**
   * Sets the type of activity volume index
   */
  set periodInHours(value) {
    this._periodInHours = value
  }

  /**
   * Gets the type of activity volume index
   */
  get range() {
    return this._range || PeriodDaysEnum.SEVEN
  }

  /**
   * Sets the type of activity volume index
   */
  set range(value) {
    this._range = value
  }

  /**
   * Returns true if the current selection is the first iterator from tree items
   * Used to hide/show the left arrow that browse organization or repository
   */
  get isFirstIterator() {
    const index = this._selectedIteratorIndex ?? 0
    return index <= 0
  }

  /**
   * Returns true if the current selection is the last iterator from tree items
   * Used to hide/show the right arrow that browse organization or repository
   */
  get isLastIterator() {
    const index = this._selectedIteratorIndex ?? 0
    return index >= this.allIterators.length - 1
  }

  /**
   * Returns true if the current selection is the first organization
   * Used to hide/show the up arrow that browse organization for mobile version
   */
  get isFirstOrganization() {
    const index = this._selectedOrganizationIndex ?? 0
    return index <= 0
  }

  /**
   * Returns true if the current selection is the last organization
   * Used to hide/show the down arrow that browse organization for mobile version
   */
  get isLastOrganization() {
    const index = this._selectedOrganizationIndex ?? 0
    return index >= this.allOrganizations.length - 1
  }

  /**
   * Returns true if the organization from index is selected
   */
  isOrganizationSelected(index: number) {
    return this._selectedOrganizationIndex === index
  }

  /**
   * Returns true if the repository from index is selected
   */
  isRepositorySelected(index: number) {
    return this._selectedRepositoryIndex === index
  }

  /**
   * Deselect organizations
   */
  deselectOrganizations() {
    this.dispatchPairSelection(null, null)
    this.deselectedActivityVolume()
  }

  /**
   * Deselect repositories
   */
  deselectRepositories() {
    this.dispatchPairSelection(null)
  }

  /**
   * Select organization by index from organization pool
   */
  selectOrganization(index: number) {
    if (this.allOrganizations[index]) {
      this.dispatchPairSelection(null, index)
    }
  }

  /**
   * Select repository by index from repository pool
   */
  selectRepository(index: number) {
    if (this.allRepositoryFromSelectedOrganization[index]) {
      this.dispatchPairSelection(index)
    }
  }

  selectToActivityVolumeIndex(index: number) {
    if (!this._selectedActivityVolumeIndex?.includes(index)) {
      this._selectedActivityVolumeIndex?.push(index)
    }
  }

  removeFromActivityVolumeIndex(index: number) {
    const indice =
      this._selectedActivityVolumeIndex?.findIndex(i => index === i) ?? -1
    if (indice >= 0) {
      this._selectedActivityVolumeIndex?.splice(indice, 1)
    }
  }

  /**
   * Select previous repository and organization tree according with the current selection
   */
  async selectPrevIterator() {
    if (!this.isFirstIterator) {
      const index = this._selectedIteratorIndex ?? 0
      this.dispatchIteratorSelection(index - 1)
    }
  }

  /**
   * Select next repository and organization tree according with the current selection
   */
  async selectNextIterator() {
    if (!this.isLastIterator) {
      const index = this._selectedIteratorIndex ?? 0
      this.dispatchIteratorSelection(index + 1)
    }
  }

  /**
   * Select previous organization according with the current selection
   */
  selectPrevOrganization() {
    if (!this.isFirstIterator) {
      const index = this._selectedOrganizationIndex ?? 0
      this.dispatchPairSelection(undefined, index - 1)
    }
  }

  /**
   * Select next organization according with the current selection
   */
  selectNextOrganization() {
    if (!this.isLastOrganization) {
      const index = this._selectedOrganizationIndex ?? 0
      this.dispatchPairSelection(undefined, index + 1)
    }
  }

  async selectActivityVolume() {
    const keys = Object.keys(this.activityVolumeDictionary)

    for (const key of keys) {
      const isSelected = this.activityVolumeDictionary[key]
      const index = Number(key)

      if (isSelected) {
        this.selectToActivityVolumeIndex(index)
      } else {
        this.removeFromActivityVolumeIndex(index)
      }
    }
  }
}
