/**
 * Commit
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {GithubUser} from '@/model/resource/GithubUser'
import {Repository} from '@/model/resource/Repository'
import {CommitCollection} from '@/model/collection/CommitCollection'

/* TODO: review generated class */
export class Commit implements IResource {
  sha: string = ''

  @ResponseSerialize(GithubUser)
  githubUser: GithubUser | null = null

  @ResponseSerialize(Repository)
  repository: Repository | null = null

  /**
   * from root.commit.author.date
   */
  datetime: string | null = null

  commentCount: number | null = null

  url: string | null = null
  message: string | null = null

  get $id() {
    // @ts-ignore
    return this.sha as number
  }
  set $id(val) {
    this.sha = String(val)
  }
  get $tag() {
    return String(this.$id)
  }

  get idRepositoryFk() {
    if (!this.repository) return 0
    return this.repository.$id
  }
  set idRepositoryFk(val) {
    if (!this.repository) this.repository = new Repository()
    this.repository.$id = val
  }

  /**
   * from root.author.id
   */
  get idGithubUserFk() {
    if (!this.githubUser) return 0
    return this.githubUser.$id
  }
  set idGithubUserFk(val) {
    if (!this.githubUser) this.githubUser = new GithubUser()
    this.githubUser.$id = val
  }

  /**
   * Gets a instance of a given ID of Commit
   */
  async getCommit(id: string) {
    return await Request.get(`/client/commit/${id}`)
      .name('getCommit')
      .as(this)
      .getData()
  }

  /**
   * Lists the instances of Commit
   */
  static async listCommit(params: any) {
    return await Request.get(`/client/commit`, {params})
      .name('listCommit')
      .as(CommitCollection)
      .getData()
  }

  /**
   * Persists a new instance of Commit. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistCommit() {
    return await Request.post(`/client/commit`, this)
      .name('persistCommit')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Commit to export as a file
   */
  static async listExportCommit(params: any) {
    return await Request.get(`/client/commit/export`, {params})
      .name('listExportCommit')
      .as(CommitCollection)
      .getData()
  }
}
