/**
 * RepositoryCollection
 *
 * @author Simpli CLI generator
 */
import {HttpExclude, Request, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {Repository} from '@/model/resource/Repository'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'

/* TODO: review generated class */
@HttpExclude()
export class RepositoryCollection extends ExpansibleCollection<Repository> {
  constructor() {
    super(Repository)
  }

  resource?: IRepositoryCollectionResourcesHolder

  @RequestExpose() idOrganizationFk: number[] = []

  get organization() {
    return (
      this.resource?.collectionOrganization.getManyIds(this.idOrganizationFk) ??
      null
    )
  }
  set organization(input) {
    this.idOrganizationFk = input?.map(item => item?.$id) ?? []
  }

  queryAsPage() {
    return this.listRepository()
  }

  async listRepository() {
    return await Request.get(`/client/repository`, {params: this.params})
      .name('listRepository')
      .as(this)
      .getResponse()
  }

  async listExportRepository() {
    return await Request.get(`/client/repository/export`, {params: this.params})
      .name('listExportRepository')
      .as(this)
      .getResponse()
  }
}

export interface IRepositoryCollectionResourcesHolder {
  collectionOrganization: OrganizationCollection
}
