/**
 * OrganizationCacheCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {OrganizationCache} from '@/model/resource/OrganizationCache'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'

/* TODO: review generated class */
@HttpExclude()
export class OrganizationCacheCollection extends PageCollection<
  OrganizationCache
> {
  constructor() {
    super(OrganizationCache)
  }

  resource?: IOrganizationCacheCollectionResourcesHolder

  @RequestExpose() startLastCommitAt: string | null = null
  @RequestExpose() endLastCommitAt: string | null = null
  @RequestExpose() startLastIssueAt: string | null = null
  @RequestExpose() endLastIssueAt: string | null = null
  @RequestExpose() startLastPullAt: string | null = null
  @RequestExpose() endLastPullAt: string | null = null
  @RequestExpose() minStargazersCount: number | null = null
  @RequestExpose() maxStargazersCount: number | null = null
  @RequestExpose() minWatchersCount: number | null = null
  @RequestExpose() maxWatchersCount: number | null = null
  @RequestExpose() minForksCount: number | null = null
  @RequestExpose() maxForksCount: number | null = null
  @RequestExpose() minOpenIssuesCount: number | null = null
  @RequestExpose() maxOpenIssuesCount: number | null = null
  @RequestExpose() minNetworkCount: number | null = null
  @RequestExpose() maxNetworkCount: number | null = null
  @RequestExpose() minSubscriptionsCount: number | null = null
  @RequestExpose() maxSubscriptionsCount: number | null = null
  @RequestExpose() minContributorCount: number | null = null
  @RequestExpose() maxContributorCount: number | null = null
  @RequestExpose() minAdditionsCount: number | null = null
  @RequestExpose() maxAdditionsCount: number | null = null
  @RequestExpose() minDeletionsCount: number | null = null
  @RequestExpose() maxDeletionsCount: number | null = null

  queryAsPage() {
    return this.listOrganizationCache()
  }

  async listOrganizationCache() {
    return await Request.get(`/client/organization-cache`, {
      params: this.params,
    })
      .name('listOrganizationCache')
      .as(this)
      .getResponse()
  }

  async listExportOrganizationCache() {
    return await Request.get(`/client/organization-cache/export`, {
      params: this.params,
    })
      .name('listExportOrganizationCache')
      .as(this)
      .getResponse()
  }
}

export interface IOrganizationCacheCollectionResourcesHolder {
  collectionOrganization: OrganizationCollection
}
