/**
 * RepositoryCache
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Repository} from '@/model/resource/Repository'
import {RepositoryCacheCollection} from '@/model/collection/RepositoryCacheCollection'

/* TODO: review generated class */
export class RepositoryCache implements IResource {
  @ResponseSerialize(Repository)
  repository: Repository | null = null

  /**
   * calculated and updated from commit table
   */
  lastCommitAt: string | null = null

  /**
   * calculated and updated from issue table
   */
  lastIssueAt: string | null = null

  /**
   * calculated and updated from pull table
   */
  lastPullAt: string | null = null

  /**
   * calculated and updated from contributor_repository table
   */
  contributorCount: number | null = null

  /**
   * calculated and updated from weekly_contribution table
   */
  additionsCount: number | null = null

  /**
   * calculated and updated from weekly_contribution table
   */
  deletionsCount: number | null = null

  linesOfCode: number | null = null
  description: string | null = null
  defaultBranch: string | null = null
  createdAt: string | null = null
  size: number | null = null
  stargazersCount: number | null = null
  watchersCount: number | null = null
  forksCount: number | null = null
  openIssuesCount: number | null = null
  networkCount: number | null = null
  subscriptionsCount: number | null = null

  homepage: string | null = null
  updatedAt: string | null = null
  pushedAt: string | null = null

  get $id() {
    /* TODO: define the ID */
    return 0
  }
  set $id(val) {
    /* TODO: define the ID */
  }
  get $tag() {
    return String(this.$id)
  }

  get idRepositoryFk() {
    if (!this.repository) return 0
    return this.repository.$id
  }
  set idRepositoryFk(val) {
    if (!this.repository) this.repository = new Repository()
    this.repository.$id = val
  }

  /**
   * Gets a instance of a given ID of RepositoryCache
   */
  async getRepositoryCache(id: number) {
    return await Request.get(`/client/repository-cache/${id}`)
      .name('getRepositoryCache')
      .as(this)
      .getData()
  }

  /**
   * Lists the instances of RepositoryCache
   */
  static async listRepositoryCache(params: any) {
    return await Request.get(`/client/repository-cache`, {params})
      .name('listRepositoryCache')
      .as(RepositoryCacheCollection)
      .getData()
  }

  /**
   * Persists a new instance of RepositoryCache. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistRepositoryCache() {
    return await Request.post(`/client/repository-cache`, this)
      .name('persistRepositoryCache')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of RepositoryCache to export as a file
   */
  static async listExportRepositoryCache(params: any) {
    return await Request.get(`/client/repository-cache/export`, {params})
      .name('listExportRepositoryCache')
      .as(RepositoryCacheCollection)
      .getData()
  }
}
