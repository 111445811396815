import {IResource} from '@simpli/resource-collection'
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {SocialType} from '@/model/resource/SocialType'
import {Organization} from '@/model/resource/Organization'
import {plainToClass} from 'class-transformer'
import {OrganizationSocialCollection} from '../collection/OrganizationSocialCollection'

export class OrganizationSocial implements IResource {
  @ResponseSerialize(Organization)
  organization: Organization | null = null

  @ResponseSerialize(SocialType)
  socialType: SocialType | null = null

  url: string | null = null

  get $id() {
    return this.idOrganizationFk
  }

  set $id(val) {
    if (!this.organization) this.organization = new Organization()
    this.organization.$id = val
  }

  get $tag() {
    return String(this.$id)
  }

  get idOrganizationFk() {
    if (!this.organization) return 0
    return this.organization.$id
  }

  set idOrganizationFk(val) {
    if (!this.organization) this.organization = new Organization()
    this.organization.$id = val
  }

  get idSocialTypeFk() {
    if (!this.socialType) return 0
    return this.socialType.$id
  }

  set idSocialTypeFk(val) {
    if (!this.socialType) this.socialType = new SocialType()
    this.socialType.$id = val
  }

  /**
   * Persists a new instance of OrganizationSocial. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistOrganizationSocial() {
    return await Request.post(`/client/organization-social`, this)
      .name('persistOrganizationSocial')
      .asNumber()
      .getData()
  }

  /**
   * Deletes a instance of a given ID of OrganizationSocial
   */
  async removeOrganizationSocial() {
    return await Request.delete(
      `/client/organization-social/${this.idOrganizationFk}/${this.idSocialTypeFk}`
    )
      .name('removeOrganizationSocial')
      .asNumber()
      .getData()
  }
}
