/**
 * GithubUser
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {GithubUserCollection} from '@/model/collection/GithubUserCollection'

/* TODO: review generated class */
export class GithubUser implements IResource {
  /**
   * its an external id, not auto incremented
   */
  idGithubUserPk: number = 0

  login: string | null = null

  avatarUrl: string | null = null
  url: string | null = null

  get $id() {
    return this.idGithubUserPk
  }
  set $id(val) {
    this.idGithubUserPk = val
  }
  get $tag() {
    return String(this.$id)
  }

  /**
   * Gets a instance of a given ID of GithubUser
   */
  async getGithubUser(id: number) {
    return await Request.get(`/client/github-user/${id}`)
      .name('getGithubUser')
      .as(this)
      .getData()
  }

  /**
   * Lists the instances of GithubUser
   */
  static async listGithubUser(params: any) {
    return await Request.get(`/client/github-user`, {params})
      .name('listGithubUser')
      .as(GithubUserCollection)
      .getData()
  }

  /**
   * Persists a new instance of GithubUser. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistGithubUser() {
    return await Request.post(`/client/github-user`, this)
      .name('persistGithubUser')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of GithubUser to export as a file
   */
  static async listExportGithubUser(params: any) {
    return await Request.get(`/client/github-user/export`, {params})
      .name('listExportGithubUser')
      .as(GithubUserCollection)
      .getData()
  }
}
