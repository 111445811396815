/**
 * RepositoryCacheCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {RepositoryCache} from '@/model/resource/RepositoryCache'
import {RepositoryCollection} from '@/model/collection/RepositoryCollection'

/* TODO: review generated class */
@HttpExclude()
export class RepositoryCacheCollection extends PageCollection<RepositoryCache> {
  constructor() {
    super(RepositoryCache)
  }

  resource?: IRepositoryCacheCollectionResourcesHolder

  @RequestExpose() startCreatedAt: string | null = null
  @RequestExpose() endCreatedAt: string | null = null
  @RequestExpose() startUpdatedAt: string | null = null
  @RequestExpose() endUpdatedAt: string | null = null
  @RequestExpose() startPushedAt: string | null = null
  @RequestExpose() endPushedAt: string | null = null
  @RequestExpose() startLastCommitAt: string | null = null
  @RequestExpose() endLastCommitAt: string | null = null
  @RequestExpose() startLastIssueAt: string | null = null
  @RequestExpose() endLastIssueAt: string | null = null
  @RequestExpose() startLastPullAt: string | null = null
  @RequestExpose() endLastPullAt: string | null = null
  @RequestExpose() minSize: number | null = null
  @RequestExpose() maxSize: number | null = null
  @RequestExpose() minStargazersCount: number | null = null
  @RequestExpose() maxStargazersCount: number | null = null
  @RequestExpose() minWatchersCount: number | null = null
  @RequestExpose() maxWatchersCount: number | null = null
  @RequestExpose() minForksCount: number | null = null
  @RequestExpose() maxForksCount: number | null = null
  @RequestExpose() minOpenIssuesCount: number | null = null
  @RequestExpose() maxOpenIssuesCount: number | null = null
  @RequestExpose() minNetworkCount: number | null = null
  @RequestExpose() maxNetworkCount: number | null = null
  @RequestExpose() minsubscriptionsCount: number | null = null
  @RequestExpose() maxsubscriptionsCount: number | null = null
  @RequestExpose() minContributorCount: number | null = null
  @RequestExpose() maxContributorCount: number | null = null
  @RequestExpose() minAdditionsCount: number | null = null
  @RequestExpose() maxAdditionsCount: number | null = null
  @RequestExpose() minDeletionsCount: number | null = null
  @RequestExpose() maxDeletionsCount: number | null = null

  queryAsPage() {
    return this.listRepositoryCache()
  }

  async listRepositoryCache() {
    return await Request.get(`/client/repository-cache`, {params: this.params})
      .name('listRepositoryCache')
      .as(this)
      .getResponse()
  }

  async listExportRepositoryCache() {
    return await Request.get(`/client/repository-cache/export`, {
      params: this.params,
    })
      .name('listExportRepositoryCache')
      .as(this)
      .getResponse()
  }
}

export interface IRepositoryCacheCollectionResourcesHolder {
  collectionRepository: RepositoryCollection
}
