import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseExpose,
} from '@simpli/serialized-request'
import {classToPlain} from 'class-transformer'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'
import {RepositoryCollection} from '@/model/collection/RepositoryCollection'

@HttpExclude()
export class GithubActivityStats {
  resource?: IGithubActivityStatsResourcesHolder

  @RequestExpose()
  idRepositoryFk: number[] = []

  @RequestExpose()
  idOrganizationFk: number[] = []

  get repository() {
    return (
      this.resource?.collectionRepository.getManyIds(this.idRepositoryFk) ??
      null
    )
  }
  set repository(input) {
    this.idRepositoryFk = input?.map(item => item?.$id) ?? []
  }

  get organization() {
    return (
      this.resource?.collectionOrganization.getManyIds(this.idOrganizationFk) ??
      null
    )
  }
  set organization(input) {
    this.idOrganizationFk = input?.map(item => item?.$id) ?? []
  }

  @ResponseExpose()
  commits: number = 0

  @ResponseExpose()
  codedLines: number = 0

  @ResponseExpose()
  contributors: number = 0

  @ResponseExpose()
  popularity: number = 0

  @ResponseExpose()
  topLanguages: LanguageItem[] = []

  get params() {
    return classToPlain(this)
  }

  /**
   * Gets statistic results for WeeklyContribution
   */
  async getStats() {
    const {params} = this

    return await Request.get(`/client/repository/github-activity-stats`, {
      params,
    })
      .name('getGithubActivityStats')
      .as(this)
      .getData()
  }
}

export interface LanguageItem {
  name: string
  amountInBytes: number
  ratio: number
}

export interface IGithubActivityStatsResourcesHolder {
  collectionOrganization: OrganizationCollection
  collectionRepository: RepositoryCollection
}
