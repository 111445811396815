/**
 * CommitCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {Commit} from '@/model/resource/Commit'
import {GithubUserCollection} from '@/model/collection/GithubUserCollection'
import {RepositoryCollection} from '@/model/collection/RepositoryCollection'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'

/* TODO: review generated class */
@HttpExclude()
export class CommitCollection extends ExpansibleCollection<Commit> {
  constructor() {
    super(Commit)
  }

  resource?: ICommitCollectionResourcesHolder

  @RequestExpose() idOrganizationFk: number[] = []
  @RequestExpose() idRepositoryFk: number[] = []
  @RequestExpose() idGithubUserFk: number[] = []
  @RequestExpose() startDatetime: string | null = null
  @RequestExpose() endDatetime: string | null = null
  @RequestExpose() minCommentCount: number | null = null
  @RequestExpose() maxCommentCount: number | null = null

  get repository() {
    return (
      this.resource?.collectionRepository.getManyIds(this.idRepositoryFk) ??
      null
    )
  }
  set repository(input) {
    this.idRepositoryFk = input?.map(item => item?.$id) ?? []
  }

  get organization() {
    return (
      this.resource?.collectionOrganization.getManyIds(this.idOrganizationFk) ??
      null
    )
  }
  set organization(input) {
    this.idOrganizationFk = input?.map(item => item?.$id) ?? []
  }

  get contributor() {
    return (
      this.resource?.collectionGithubUser.getManyIds(this.idGithubUserFk) ??
      null
    )
  }
  set contributor(input) {
    this.idGithubUserFk = input?.map(item => item?.$id) ?? []
  }

  queryAsPage() {
    return this.listCommit()
  }

  async listCommit() {
    return await Request.get(`/client/commit`, {params: this.params})
      .name('listCommit')
      .as(this)
      .getResponse()
  }

  async listExportCommit() {
    return await Request.get(`/client/commit/export`, {params: this.params})
      .name('listExportCommit')
      .as(this)
      .getResponse()
  }
}

export interface ICommitCollectionResourcesHolder {
  collectionGithubUser: GithubUserCollection
  collectionRepository: RepositoryCollection
  collectionOrganization: OrganizationCollection
}
