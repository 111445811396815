/**
 * OrganizationCache
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {IResource} from '@simpli/resource-collection/dist/types/IResource'
import {Organization} from '@/model/resource/Organization'
import {OrganizationCacheCollection} from '@/model/collection/OrganizationCacheCollection'

/* TODO: review generated class */
export class OrganizationCache implements IResource {
  @ResponseSerialize(Organization)
  organization: Organization | null = null

  /**
   * calculated and updated from repository_cache table
   */
  lastCommitAt: string | null = null

  /**
   * calculated and updated from repository_cache table
   */
  lastIssueAt: string | null = null

  /**
   * calculated and updated from repository_cache table
   */
  lastPullAt: string | null = null

  /**
   * calculated and updated from repository_cache table
   */
  stargazersCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  watchersCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  forksCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  openIssuesCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  networkCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  subscriptionsCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  contributorCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  additionsCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  deletionsCount: number | null = null

  /**
   * calculated and updated from repository_cache table
   */
  linesOfCode: number | null = null

  get $id() {
    /* TODO: define the ID */
    return 0
  }
  set $id(val) {
    /* TODO: define the ID */
  }
  get $tag() {
    return String(this.$id)
  }

  get idOrganizationFk() {
    if (!this.organization) return 0
    return this.organization.$id
  }
  set idOrganizationFk(val) {
    if (!this.organization) this.organization = new Organization()
    this.organization.$id = val
  }

  /**
   * Gets a instance of a given ID of OrganizationCache
   */
  async getOrganizationCache(id: number) {
    return await Request.get(`/client/organization-cache/${id}`)
      .name('getOrganizationCache')
      .as(this)
      .getData()
  }

  /**
   * Lists the instances of OrganizationCache
   */
  static async listOrganizationCache(params: any) {
    return await Request.get(`/client/organization-cache`, {params})
      .name('listOrganizationCache')
      .as(OrganizationCacheCollection)
      .getData()
  }

  /**
   * Persists a new instance of OrganizationCache. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistOrganizationCache() {
    return await Request.post(`/client/organization-cache`, this)
      .name('persistOrganizationCache')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of OrganizationCache to export as a file
   */
  static async listExportOrganizationCache(params: any) {
    return await Request.get(`/client/organization-cache/export`, {params})
      .name('listExportOrganizationCache')
      .as(OrganizationCacheCollection)
      .getData()
  }
}
