/**
 * GithubUserCollection
 *
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {
  HttpExclude,
  Request,
  RequestExpose,
  ResponseSerialize,
} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {GithubUser} from '@/model/resource/GithubUser'

/* TODO: review generated class */
@HttpExclude()
export class GithubUserCollection extends PageCollection<GithubUser> {
  constructor() {
    super(GithubUser)
  }

  resource?: IGithubUserCollectionResourcesHolder

  queryAsPage() {
    return this.listGithubUser()
  }

  async listGithubUser() {
    return await Request.get(`/client/github-user`, {params: this.params})
      .name('listGithubUser')
      .as(this)
      .getResponse()
  }

  async listExportGithubUser() {
    return await Request.get(`/client/github-user/export`, {
      params: this.params,
    })
      .name('listExportGithubUser')
      .as(this)
      .getResponse()
  }
}

export interface IGithubUserCollectionResourcesHolder {}
