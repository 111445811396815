import {
  HttpExclude,
  HttpExpose,
  Request,
  RequestExpose,
  ResponseExpose,
} from '@simpli/serialized-request'
import {classToPlain} from 'class-transformer'
import {OrganizationCollection} from '@/model/collection/OrganizationCollection'

@HttpExclude()
export class StackedChartStats {
  resource?: ICommitStatsResourcesHolder

  @RequestExpose()
  idOrganizationFk: number[] = []

  get organization() {
    return (
      this.resource?.collectionOrganization.getManyIds(this.idOrganizationFk) ??
      null
    )
  }

  set organization(input) {
    this.idOrganizationFk = input?.map(item => item?.$id) ?? []
  }

  /**
   * Range of data
   * Filter only available for periodInSeconds
   */
  @HttpExpose()
  range: number | null = null

  /**
   * Raw stats data
   */
  @ResponseExpose()
  lines: Map<string, number[]> = new Map<string, number[]>()

  /**
   * Raw stats data
   */
  @ResponseExpose()
  commits: Map<string, number[]> = new Map<string, number[]>()

  get params() {
    return classToPlain(this)
  }

  /**
   * Load commit stats for session 'Activity Volume' and weekly contribution stats for session 'Activity Volume'
   * The period of each step is always weekly
   */
  async getStats() {
    const {params} = this

    return await Request.get(`/client/repository/activity-volume-stats`, {
      params,
    })
      .name('getCommitStats')
      .as(this)
      .getData()
  }
}

export interface ICommitStatsResourcesHolder {
  collectionOrganization: OrganizationCollection
}
