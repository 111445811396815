/**
 * Repository
 * @author Simpli CLI generator
 */
import {Request, ResponseSerialize} from '@simpli/serialized-request'
import {Organization} from '@/model/resource/Organization'
import {RepositoryCollection} from '@/model/collection/RepositoryCollection'
import {RepositoryCache} from '@/model/resource/RepositoryCache'
import {RepositoryLanguage} from '@/model/resource/RepositoryLanguage'
import {CAIterable, CAType} from '@/app/CAController'
import moment, {max, Moment} from 'moment'
import {plainToClass} from 'class-transformer'

/* TODO: review generated class */
export class Repository implements CAIterable {
  idRepositoryPk: number = 0

  @ResponseSerialize(Organization)
  organization: Organization | null = null

  @ResponseSerialize(RepositoryCache)
  repositoryCache: RepositoryCache | null = null

  @ResponseSerialize(RepositoryLanguage)
  repositoryLanguage: RepositoryLanguage[] | null = null

  /**
   * labels separated by comma
   */
  labelsTokenized: string | null = null

  title: string | null = null
  description: string | null = null
  colorHex: string | null = null
  repositoryUrl: string | null = null

  caType = CAType.REPOSITORY

  get $id() {
    return this.idRepositoryPk
  }
  set $id(val) {
    this.idRepositoryPk = val
  }
  get $tag() {
    return String(this.title)
  }

  get idOrganizationFk() {
    return this.organization?.$id || 0
  }

  set idOrganizationFk(val) {
    if (!this.organization) this.organization = new Organization()
    this.organization.$id = val
  }

  get idRepositoryCacheFk() {
    if (!this.repositoryCache) return 0
    return this.repositoryCache.$id
  }
  set idRepositoryCacheFk(val) {
    if (!this.repositoryCache) this.repositoryCache = new RepositoryCache()
    this.repositoryCache.$id = val
  }

  get activitiesCount() {
    return this.repositoryCache?.linesOfCode ?? 0
  }

  get logoUrl() {
    return this.organization?.logoUrl ?? ''
  }

  get lastUpdateAt() {
    const lastCommitAt = moment(this.repositoryCache?.lastCommitAt)
    const lastIssueAt = moment(this.repositoryCache?.lastIssueAt)
    const lastPullAt = moment(this.repositoryCache?.lastPullAt)

    const moments: Moment[] = []

    if (lastCommitAt.isValid()) {
      moments.push(lastCommitAt)
    }

    if (lastIssueAt.isValid()) {
      moments.push(lastIssueAt)
    }

    if (lastPullAt.isValid()) {
      moments.push(lastPullAt)
    }

    const result = max(moments).format()
    return moment(result).isValid() ? result : null
  }

  /**
   * Gets a instance of a given ID of Repository
   */
  async getRepository(id: number) {
    const data = await Request.get(`/client/repository/${id}`)
      .name('getRepository')
      .as(this)
      .getData()

    if (this.organization) {
      this.organization = plainToClass(Organization, this.organization)
    }

    return data
  }

  /**
   * Lists the instances of Repository
   */
  static async listRepository(params: any) {
    return await Request.get(`/client/repository`, {params})
      .name('listRepository')
      .as(RepositoryCollection)
      .getData()
  }

  /**
   * Persists a new instance of Repository. Use ID = 0 to create a new one,
   * or ID > 0 to update a current one
   */
  async persistRepository() {
    return await Request.post(`/client/repository`, this)
      .name('persistRepository')
      .asNumber()
      .getData()
  }

  /**
   * Remove a Repository.
   */
  async removeRepository() {
    return await Request.delete(`/client/repository/${this.idRepositoryPk}`)
      .name('removeRepository')
      .asNumber()
      .getData()
  }

  /**
   * Lists the instances of Repository to export as a file
   */
  static async listExportRepository(params: any) {
    return await Request.get(`/client/repository/export`, {params})
      .name('listExportRepository')
      .as(RepositoryCollection)
      .getData()
  }
}
